import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon,
RectangleGroupIcon,
UsersIcon,
SparklesIcon,
RocketLaunchIcon


 } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Responsive email block',
    description:
      'Incorporate responsive email blocks in Mailatmars editor to ensure your content looks great on all devices effortlessly',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Unique 5+ blocks',
    description: 'Header, product showcase, testimonial, social media integration, and footer blocks enhance brand identity and engagement in branded email templates',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Easy integrate social media',
    description: 'Effortlessly incorporate social media icons into your emails using Mailatmars intuitive email editor for enhanced engagement and connectivity.',
    icon: UsersIcon,
  },
  {
    name: 'Test in various plateform',
    description: 'Straightforward test email editor streamlines the process, facilitating quick iteration and optimization for superior campaign results',
    icon: SparklesIcon,
  },
  {
    name: 'No branding in free plan',
    description: 'Access Mailatmars email editor for free, empowering you to design and send professional campaigns without any financial commitment',
    icon: RocketLaunchIcon,
  },
]


const feature_of_template=[
  {
    name: 'Own coded design',
    description:
      'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
]

const features_new=[
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]

const products = [
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

  <Header />


      <div className="relative isolate  pt-14 ">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div class="overflow-hidden pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
            <div className="hidden sm:mb-8 sm:flex sm:justify-left">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Intuitive and Powerful Email Editor

              </div>
            </div>
              <div className="lg:max-w-lg">

                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Mailatmars: Streamlined, Intuitive Email Design Interface</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                Mailatmars email editor offers a user-friendly interface with drag-and-drop functionality, customizable templates, and real-time previews, ensuring effortless creation of stunning and responsive email campaigns
                </p>

              </div>
              <div className="mt-10 flex items-center  gap-x-6">
                <a
                  href="https://account-v2.mailatmars.com/account/create"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
              </div>
            </div>
            <img
              src="https://res.cloudinary.com/dttmahifk/image/upload/v1717323828/Screenshot_2024-06-02_at_3.53.25_PM_eedbno.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
        </div>


        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>


            <div className="relative isolate  pt-14 ">
              <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
              >
                <div
                  className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                />
              </div>

              <div class="overflow-hidden pb-10">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                  <div className="lg:pr-8 lg:pt-4">
                  <div className="hidden sm:mb-8 sm:flex sm:justify-left">
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                      Use batch campaign for your best result

                    </div>
                  </div>
                    <div className="lg:max-w-lg">

                      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">  Split Campaign for Optimal Results</p>
                      <p className="mt-6 text-lg leading-8 text-gray-600">
                       Mailatmars optimizes your email campaigns by splitting your list and sending emails at hourly intervals, ensuring the best results and maximizing both engagement and deliverability for your messages
                      </p>

                    </div>
                    <div className="mt-10 flex items-center  gap-x-6">
                      <a
                        href="https://account-v2.mailatmars.com/account/create"
                        className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Get started
                      </a>
                      <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
                    </div>
                  </div>
                  <img
                    src="https://res.cloudinary.com/dttmahifk/image/upload/v1717238002/Screenshot_2024-06-01_at_4.02.56_PM_auimfs.png"
                    alt="Product screenshot"
                    className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                    width={2432}
                    height={1442}
                  />
                </div>
              </div>
              </div>


              <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true"
              >
                <div
                  className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                />
              </div>
            </div>


                  <div className="relative isolate  pt-14 ">
                    <div
                      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                      aria-hidden="true"
                    >
                      <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                          clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                      />
                    </div>

                    <div class="overflow-hidden pb-10">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                        <div className="hidden sm:mb-8 sm:flex sm:justify-left">
                          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                            Optimize Campaigns with A/B Testing

                          </div>
                        </div>
                          <div className="lg:max-w-lg">

                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Improve Engagement with A/B Testing</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            Mailatmars A/B testing involves comparing two versions of an email to determine which performs better. By testing variables like subject lines and content, you can optimize engagement and improve overall campaign effectiveness
                            </p>

                          </div>
                          <div className="mt-10 flex items-center  gap-x-6">
                            <a
                              href="https://account-v2.mailatmars.com/account/create"
                              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Get started
                            </a>
                            <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
                          </div>
                        </div>
                        <img
                          src="https://res.cloudinary.com/dttmahifk/image/upload/v1717387310/ab-testing_lnijyl.webp"
                          alt="Product screenshot"
                          className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                          width={2432}
                          height={1442}
                        />
                      </div>
                    </div>
                    </div>


                    <div
                      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                      aria-hidden="true"
                    >
                      <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                          clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                      />
                    </div>
                  </div>


                  <div className="relative isolate  pt-14 ">
                    <div
                      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                      aria-hidden="true"
                    >
                      <div
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style={{
                          clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                      />
                    </div>

                    <div class="overflow-hidden pb-10">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                        <div className="hidden sm:mb-8 sm:flex sm:justify-left">
                          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                            Automation

                          </div>
                        </div>
                          <div className="lg:max-w-lg">

                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Streamline your campaigns with powerful, efficient marketing automation solutions.</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                        Automate your email workflows to save time and increase efficiency. Seamlessly integrate and optimize your campaigns, streamline repetitive tasks, and enhance your marketing strategy with advanced automation tools.
                            </p>

                          </div>
                          <div className="mt-10 flex items-center  gap-x-6">
                            <a
                              href="https://account-v2.mailatmars.com/account/create"
                              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Get started
                            </a>
                            <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
                          </div>
                        </div>
                        <img
                          src="https://res.cloudinary.com/dttmahifk/image/upload/v1722510656/Untitled_design_21_x62yu9.png"
                          alt="Product screenshot"
                          className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                          width={2432}
                          height={1442}
                        />
                      </div>
                    </div>
                    </div>


                    <div
                      className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                      aria-hidden="true"
                    >
                      <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                          clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                      />
                    </div>
                  </div>



                        <div className="relative isolate  pt-14 ">
                          <div
                            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                          >
                            <div
                              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                              style={{
                                clipPath:
                                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                              }}
                            />
                          </div>

                          <div class="overflow-hidden pb-10">
                          <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                              <div className="lg:pr-8 lg:pt-4">
                              <div className="hidden sm:mb-8 sm:flex sm:justify-left">
                                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                  Automated series for targeted outreach

                                </div>
                              </div>
                                <div className="lg:max-w-lg">

                                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Automated sequence for personalized and timely engagement</p>
                                  <p className="mt-6 text-lg leading-8 text-gray-600">
                                Mailatmars drip campaign feature allows you to automate personalized email sequences, delivering timely and targeted messages to nurture leads and drive conversions effectively
                                  </p>

                                </div>
                                <div className="mt-10 flex items-center  gap-x-6">
                                  <a
                                    href="https://account-v2.mailatmars.com/account/create"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    Get started
                                  </a>
                                  <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
                                </div>
                              </div>
                              <img
                                src="https://res.cloudinary.com/dttmahifk/image/upload/v1717410992/Untitled_design_5_vhcfyb.png"
                                alt="Product screenshot"
                                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                width={2432}
                                height={1442}
                              />
                            </div>
                          </div>
                          </div>


                          <div
                            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                          >
                            <div
                              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                              style={{
                                clipPath:
                                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                              }}
                            />
                          </div>
                        </div>



                        <div className="relative isolate  pt-14 ">
                          <div
                            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                          >
                            <div
                              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                              style={{
                                clipPath:
                                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                              }}
                            />
                          </div>

                          <div class="overflow-hidden pb-10">
                          <div className="mx-auto max-w-7xl px-6 lg:px-8">
                            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                              <div className="lg:pr-8 lg:pt-4">
                              <div className="hidden sm:mb-8 sm:flex sm:justify-left">
                                <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                                  Insightful Analytics for Campaign

                                </div>
                              </div>
                                <div className="lg:max-w-lg">

                                  <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Maximize Your Success with Detailed Analytics</p>
                                  <p className="mt-6 text-lg leading-8 text-gray-600">
                                Unlock the power of your campaigns with detailed analytics. Track opens, clicks, and conversions to optimize performance, improve engagement, and achieve better results with data-driven insights
                                  </p>

                                </div>
                                <div className="mt-10 flex items-center  gap-x-6">
                                  <a
                                    href="https://account-v2.mailatmars.com/account/create"
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                  >
                                    Get started
                                  </a>
                                  <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
                                </div>
                              </div>
                              <img
                                src="https://res.cloudinary.com/dttmahifk/image/upload/v1717413140/Untitled_design_6_vtcpck.png"
                                alt="Product screenshot"
                                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                width={2432}
                                height={1442}
                              />
                            </div>
                          </div>
                          </div>


                          <div
                            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                          >
                            <div
                              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                              style={{
                                clipPath:
                                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                              }}
                            />
                          </div>
                        </div>




<Feature />


  <Pricing />

<Stats />

<Review />

    <Cta />

<Footer />
 
    </div>
  )
}
