import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon,
RectangleGroupIcon,
UsersIcon,
SparklesIcon,
RocketLaunchIcon


 } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

  <Header />

  <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">

      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Acceptable use policy</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Last update : April 30, 2024
          </p>
        </div>

      </div>
    </div>

    <div className="main-terms-container m-auto mt-20 mb-20 text-sm" style={{width:"80%"}}>

    <>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          This Acceptable Use Policy ("Policy") is part of our Terms and Conditions
          ("Legal Terms") and should therefore be read alongside our main Legal
          Terms:&nbsp;
        </span>
        <a href="https://mailatmars.com/terms">
          <u>
            <span style={{ color: "#3030f1", fontSize: "10.5pt" }}>
              https://mailatmars.com/terms
            </span>
          </u>
        </a>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          . If you do not agree with these Legal Terms, please refrain from using
          our Services. Your continued use of our Services implies acceptance of
          these Legal Terms.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          Please carefully review this Policy which applies to any and all:
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          (a) uses of our Services (as defined in "Legal Terms")
        </span>
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          (b) forms, materials, consent tools, comments, post, and all other content
          available on the Services ("Content") and
        </span>
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          (c) material which you contribute to the Services including any upload,
          post, review, disclosure, ratings, comments, chat etc. in any forum,
          chatrooms, reviews, and to any interactive services associated with it
          ("Contribution").
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>WHO WE ARE</span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          We are mailatmars technologies LLP ("Company," "we," "us," or "our") a
          company registered in India at 1262, SAINAGAR,, MEDANKARWADI, CHAKAN,,
          PUNE, MAHARASTRA 410501. We operate the website&nbsp;
        </span>
        <a href="https://mailatmars.com/">
          <u>
            <span style={{ color: "#3030f1", fontSize: "10.5pt" }}>
              https://mailatmars.com
            </span>
          </u>
        </a>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          &nbsp;(the "Site"), as well as any other related products and services
          that refer or link to this Policy (collectively, the "Services").
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>USE OF THE SERVICES</span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          When you use the Services you warrant that you will comply with this
          Policy and with all applicable laws.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          You also acknowledge that you may not:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Systematically retrieve data or other content from the Services to
              create or compile, directly or indirectly, a collection, compilation,
              database, or directory without written permission from us.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Make any unauthorized use of the Services, including collecting
              usernames and/or email addresses of users by electronic or other means
              for the purpose of sending unsolicited email, or creating user
              accounts by automated means or under false pretenses.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services, including features that prevent or restrict
              the use or copying of any Content or enforce limitations on the use of
              the Services and/or the Content contained therein.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Engage in unauthorized framing of or linking to the Services.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Trick, defraud, or mislead us and other users, especially in any
              attempt to learn sensitive account information such as user passwords.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Make improper use of our Services, including our support services or
              submit false reports of abuse or misconduct.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Engage in any automated use of the Services, such as using scripts to
              send comments or messages, or using any data mining, robots, or
              similar data gathering and extraction tools.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Interfere with, disrupt, or create an undue burden on the Services or
              the networks or the Services connected.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Attempt to impersonate another user or person or use the username of
              another user.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Use any information obtained from the Services in order to harass,
              abuse, or harm another person.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Use the Services as part of any effort to compete with us or otherwise
              use the Services and/or the Content for any revenue-generating
              endeavor or commercial enterprise.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Decipher, decompile, disassemble, or reverse engineer any of the
              software comprising or in any way making up a part of the Services,
              except as expressly permitted by applicable law.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Attempt to bypass any measures of the Services designed to prevent or
              restrict access to the Services, or any portion of the Services.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Harass, annoy, intimidate, or threaten any of our employees or agents
              engaged in providing any portion of the Services to you.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Copy or adapt the Services’ software, including but not limited to
              Flash, PHP, HTML, JavaScript, or other code.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Upload or transmit (or attempt to upload or to transmit) viruses,
              Trojan horses, or other material, including excessive use of capital
              letters and spamming (continuous posting of repetitive text), that
              interferes with any party’s uninterrupted use and enjoyment of the
              Services or modifies, impairs, disrupts, alters, or interferes with
              the use, features, functions, operation, or maintenance of the
              Services.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Upload or transmit (or attempt to upload or to transmit) any material
              that acts as a passive or active information collection or
              transmission mechanism, including without limitation, clear graphics
              interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other
              similar devices (sometimes referred to as "spyware" or "passive
              collection mechanisms" or "pcms").
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Except as may be the result of standard search engine or Internet
              browser usage, use, launch, develop, or distribute any automated
              system, including without limitation, any spider, robot, cheat
              utility, scraper, or offline reader that accesses the Services, or
              using or launching any unauthorized script or other software.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
              Services.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Use the Services in a manner inconsistent with any applicable laws or
              regulations.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Sell or otherwise transfer your profile.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Use a buying agent or purchasing agent to make purchases on the
              Services.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          If you subscribe to our Services, you understand, acknowledge, and agree
          that you may not, except if expressly permitted:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Engage in any use, including modification, copying, redistribution,
              publication, display, performance, or retransmission, of any portions
              of any Services, other than as expressly permitted by this Policy,
              without the prior written consent of mailatmars technologies LLP,
              which consent mailatmars technologies LLP may grant or refuse in its
              sole and absolute discretion.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Reconstruct or attempt to discover any source code or algorithms of
              the Services, or any portion thereof, by any means whatsoever.
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              Provide, or otherwise make available, the Services to any third party.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>CONTRIBUTIONS</span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          In this Policy, the term "Contributions" means:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              any data, information, software, text, code, music, scripts, sound,
              graphics, photos, videos, tags, messages, interactive features, or
              other materials that you post, share, upload, submit, or otherwise
              provide in any manner on or through to the Services; or
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              any other content, materials, or data you provide to mailatmars
              technologies LLP or use with the Services.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          Some areas of the Services may allow users to upload, transmit, or post
          Contributions. We may but are under no obligation to review or moderate
          the Contributions made on the Services, and we expressly exclude our
          liability for any loss or damage resulting from any of our users' breach
          of this Policy. Please report any Contribution that you believe breaches
          this Policy; however, we will determine, in our sole discretion, whether a
          Contribution is indeed in breach of this Policy.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          You warrant that:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              you are the creator and owner of or have the necessary licenses,
              rights, consents, releases, and permissions to use and to authorize
              us, the Services, and other users of the Services to use your
              Contributions in any manner contemplated by the Services and this
              Policy;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              all your Contributions comply with applicable laws and are original
              and true (if they represent your opinion or facts);
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              the creation, distribution, transmission, public display, or
              performance, and the accessing, downloading, or copying of your
              Contributions do not and will not infringe the proprietary rights,
              including but not limited to the copyright, patent, trademark, trade
              secret, or moral rights of any third party; and
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              you have the verifiable consent, release, and/or permission of each
              and every identifiable individual person in your Contributions to use
              the name or likeness of each and every such identifiable individual
              person to enable inclusion and use of your Contributions in any manner
              contemplated by the Services and this Policy.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          You also agree that you will not post, transmit, or upload any (or any
          part of a) Contribution that:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              is in breach of applicable laws, regulation, court order, contractual
              obligation, this Policy, our Legal Terms, a legal duty, or that
              promotes or facilitates fraud or illegal activities;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              is defamatory, obscene, offensive, hateful, insulting, intimidating,
              bullying, abusive, or threatening, to any person or group;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              is false, inaccurate, or misleading;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              includes child sexual abuse material, or violates any applicable law
              concerning child pornography or otherwise intended to protect minors;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              contains any material that solicits personal information from anyone
              under the age of 18 or exploits people under the age of 18 in a sexual
              or violent manner;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              promotes violence, advocates the violent overthrow of any government,
              or incites, encourages, or threatens physical harm against another;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              is obscene, lewd, lascivious, filthy, violent, harassing, libelous,
              slanderous, contains sexually explicit material, or is otherwise
              objectionable (as determined by us);
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              is discriminatory based on race, sex, religion, nationality,
              disability, sexual orientation, or age;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              bullies, intimidates, humiliates, or insults any person;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              promotes, facilitates, or assists anyone in promoting and facilitating
              acts of terrorism;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              infringes, or assists anyone in infringing, a third party's
              intellectual property rights or publicity or privacy rights;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              is deceitful, misrepresents your identity or affiliation with any
              person and/or misleads anyone as to your relationship with us or
              implies that the Contribution was made by someone else than you;
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              contains unsolicited or unauthorized advertising, promotional
              materials, pyramid schemes, chain letters, spam, mass mailings, or
              other forms of solicitation that has been "paid for," whether with
              monetary compensation or in kind; or
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              misrepresents your identity or who the Contribution is from.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          You may not use our Services to offer, present, promote, sell, give away
          or otherwise make available to others any good or service involving:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              items that promote, encourage, facilitate, or instruct others how to
              engage in illegal activity,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              cigarettes,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              controlled substances and/or other products that present a risk to
              consumer safety, narcotics, steroids, drug paraphernalia,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              specific knives or other weapons regulated under applicable law,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              firearms, ammunition, or certain firearm parts or accessories,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              certain sexually oriented materials or services,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              certain items before the seller has control or possession of the item,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              stolen goods,
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              products or services identified by government agencies to be highly
              likely to be fraudulent, and
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              any transaction or activity that requires pre-approval without having
              obtained said approval.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>
          REPORTING A BREACH OF THIS POLICY
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          We may but are under no obligation to review or moderate the Contributions
          made on the Services and we expressly exclude our liability for any loss
          or damage resulting from any of our users' breach of this Policy.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          If you consider that any Content or Contribution:
        </span>
      </p>
      <ul>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              breach this Policy, please email us at info@mailatmars.com, or refer
              to the contact details at the bottom of this document to let us know
              which Content or Contribution is in breach of this Policy and why; or
            </span>
          </p>
        </li>
        <li
          style={{ listStyleType: "square", color: "#595959", fontSize: "10.5pt" }}
        >
          <p>
            <span style={{ color: "#595959", fontSize: "10.5pt" }}>
              infringe any third-party intellectual property rights, please email us
              at info@mailatmars.com.
            </span>
          </p>
        </li>
      </ul>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          We will reasonably determine whether a Content or Contribution breaches
          this Policy.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>
          CONSEQUENCES OF BREACHING THIS POLICY
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          The consequences for violating our Policy will vary depending on the
          severity of the breach and the user's history on the Services, by way of
          example:
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          We may, in some cases, give you a warning and/or remove the infringing
          Contribution, however, if your breach is serious or if you continue to
          breach our Legal Terms and this Policy, we have the right to suspend or
          terminate your access to and use of our Services and, if applicable,
          disable your account. We may also notify law enforcement or issue legal
          proceedings against you when we believe that there is a genuine risk to an
          individual or a threat to public safety.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          We exclude our liability for all action we may take in response to any of
          your breaches of this Policy.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>
          COMPLAINTS AND REMOVAL OF LEGITIMATE CONTENT
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          If you consider that some Content or Contribution have been mistakenly
          removed or blocked from the Services, please refer to the contact details
          at the bottom of this document and we will promptly review our decision to
          remove such Content or Contribution. The Content or Contribution may stay
          "down" whilst we conduct the review process.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>DISCLAIMER</span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          mailatmars technologies LLP is under no obligation to monitor users’
          activities, and we disclaim any responsibility for any user’s misuse of
          the Services. mailatmars technologies LLP has no responsibility for any
          user or other Content or Contribution created, maintained, stored,
          transmitted, or accessible on or through the Services, and is not
          obligated to monitor or exercise any editorial control over such material.
          If mailatmars technologies LLP becomes aware that any such Content or
          Contribution violates this Policy, mailatmars technologies LLP may, in
          addition to removing such Content or Contribution and blocking your
          account, report such breach to the police or appropriate regulatory
          authority. Unless otherwise stated in this Policy, mailatmars technologies
          LLP disclaims any obligation to any person who has not entered into an
          agreement with mailatmars technologies LLP for the use of the Services.
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ fontSize: "14.5pt" }}>
          HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          If you have any further questions or comments or wish to report any
          problematic Content or Contribution, you may contact us by:
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <span style={{ color: "#595959", fontSize: "10.5pt" }}>
          Email: info@mailatmars.com
        </span>
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </>



    </div>


<Footer />
 
    </div>
  )
}
