import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon,
RectangleGroupIcon,
UsersIcon,
SparklesIcon,
RocketLaunchIcon


 } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Responsive email block',
    description:
      'Incorporate responsive email blocks in Mailatmars editor to ensure your content looks great on all devices effortlessly',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Unique 5+ blocks',
    description: 'Header, product showcase, testimonial, social media integration, and footer blocks enhance brand identity and engagement in branded email templates',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Easy integrate social media',
    description: 'Effortlessly incorporate social media icons into your emails using Mailatmars intuitive email editor for enhanced engagement and connectivity.',
    icon: UsersIcon,
  },
  {
    name: 'Test in various plateform',
    description: 'Straightforward test email editor streamlines the process, facilitating quick iteration and optimization for superior campaign results',
    icon: SparklesIcon,
  },
  {
    name: 'No branding in free plan',
    description: 'Access Mailatmars email editor for free, empowering you to design and send professional campaigns without any financial commitment',
    icon: RocketLaunchIcon,
  },
]


const feature_of_template=[
  {
    name: 'Own coded design',
    description:
      'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
]

const features_new=[
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]

const products = [
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

const links = [
  { name: 'Secure', href: '#' },
  { name: 'Safe', href: '#' },
  { name: 'values', href: '#' },
  { name: 'leadership', href: '#' },
]
const stats = [
  { name: 'Offices worldwide', value: '3' },
  { name: 'Full-time colleagues', value: '300+' },
  { name: 'Enterprise use', value: '40+' },
  { name: 'Emails per month', value: '5M+' },
]

const people = [
  {
    name: 'Ravi Gorasiya',
    role: 'Founder / CEO',
    imageUrl:
      'https://res.cloudinary.com/dttmahifk/image/upload/v1717477196/ravi-photo_ftlnaa.jpg',
      linkedin_url:"https://linkedin.com/in/ravi-gorasiya"
  },
  {
    name: 'Sanket Pawar',
    role: 'Co-Founder / CMO',
    imageUrl:
      'https://res.cloudinary.com/dttmahifk/image/upload/v1717477629/sanket-photo_gkcni2.jpg',
      linkedin_url:"https://www.linkedin.com/in/sanketpawar7/"
  },

]


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

  <Header />

  <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">

      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Privacy policy</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Last update : April 30, 2024
          </p>
        </div>

      </div>
    </div>

    <div className="main-terms-container m-auto mt-20 mb-20 text-sm" style={{width:"80%"}}>

    <>
  <div>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        This privacy notice for mailatmars technologies LLP ("we," "us," or
        "our"), describes how and why we might collect, store, use, and/or share
        ("process") your information when you use our services ("Services"),
        such as when you:
      </span>
    </p>
    <div style={{ marginLeft: "18pt", backgroundColor: "#ffffff" }}>
      <p
        style={{
          marginTop: "0pt",
          marginLeft: "18pt",
          marginBottom: "0pt",
          textIndent: "-18pt",
          lineHeight: "150%"
        }}
      >
        <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
        <span
          style={{
            width: "10.75pt",
            font: '7pt "Times New Roman"',
            display: "inline-block"
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span
          style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
        >
          Visit our website at&nbsp;
        </span>
        <a href="https://mailatmars.com/" style={{ textDecoration: "none" }}>
          <u>
            <span
              style={{
                lineHeight: "150%",
                fontSize: "10.5pt",
                color: "#3030f1"
              }}
            >
              https://mailatmars.com
            </span>
          </u>
        </a>
        <span
          style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
        >
          , or any website of ours that links to this privacy notice
        </span>
      </p>
    </div>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Engage with us in other related ways, including any sales, marketing, or
        events
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Questions or concerns? Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at
        sales@mailatmars.com.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      SUMMARY OF KEY POINTS
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span
          style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
        >
          This summary provides key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our&nbsp;
        </span>
      </em>
      <a
        href="https://mailatmars.com/company/privacy/#toc"
        style={{ textDecoration: "none" }}
      >
        <em>
          <u>
            <span
              style={{
                lineHeight: "150%",
                fontSize: "10.5pt",
                color: "#3030f1"
              }}
            >
              table of contents
            </span>
          </u>
        </em>
      </a>
      <em>
        <span
          style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
        >
          &nbsp;below to find the section you are looking for.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        What personal information do we process? When you visit, use, or
        navigate our Services, we may process personal information depending on
        how you interact with us and the Services, the choices you make, and the
        products and features you use. Learn more about&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#personalinfo"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            personal information you disclose to us
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Do we process any sensitive personal information? We do not process
        sensitive personal information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Do we receive any information from third parties? We do not receive any
        information from third parties.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        How do we process your information? We process your information to
        provide, improve, and administer our Services, communicate with you, for
        security and fraud prevention, and to comply with law. We may also
        process your information for other purposes with your consent. We
        process your information only when we have a valid legal reason to do
        so. Learn more about&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#infouse"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            how we process your information
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        In what situations and with which parties do we share personal
        information? We may share information in specific situations and with
        specific third parties. Learn more about&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#whoshare"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            when and with whom we share your personal information
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        How do we keep your information safe? We have organizational and
        technical processes and procedures in place to protect your personal
        information. However, no electronic transmission over the internet or
        information storage technology can be guaranteed to be 100% secure, so
        we cannot promise or guarantee that hackers, cybercriminals, or other
        unauthorized third parties will not be able to defeat our security and
        improperly collect, access, steal, or modify your information. Learn
        more about&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#infosafe"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            how we keep your information safe
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        What are your rights? Depending on where you are located geographically,
        the applicable privacy law may mean you have certain rights regarding
        your personal information. Learn more about&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#privacyrights"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            your privacy rights
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        How do you exercise your rights? The easiest way to exercise your rights
        is by submitting a&nbsp;
      </span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            data subject access request
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        , or by contacting us. We will consider and act upon any request in
        accordance with applicable data protection laws.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        Want to learn more about what we do with any information we
        collect?&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#toc"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            Review the privacy notice in full
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      TABLE OF CONTENTS
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#infocollect"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            1. WHAT INFORMATION DO WE COLLECT?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#infouse"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#legalbases"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#whoshare"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#cookies"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#inforetain"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            6. HOW LONG DO WE KEEP YOUR INFORMATION?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#infosafe"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#infominors"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            8. DO WE COLLECT INFORMATION FROM MINORS?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#privacyrights"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            9. WHAT ARE YOUR PRIVACY RIGHTS?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#DNT"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#uslaws"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#otherlaws"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#003afa" }}>
            12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#policyupdates"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            13. DO WE MAKE UPDATES TO THIS NOTICE?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#contact"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#003afa" }}>
            14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://mailatmars.com/company/privacy/#request"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      1. WHAT INFORMATION DO WE COLLECT?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Personal information you disclose to us
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "115%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We collect personal information that you provide to us.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Personal Information Provided by You. The personal information that we
        collect depends on the context of your interactions with us and the
        Services, the choices you make, and the products and features you use.
        The personal information we collect may include the following:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>names</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>phone numbers</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>email addresses</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>passwords</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>usernames</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>billing addresses</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>company / organization</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>website</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Sensitive Information. We do not process sensitive information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Payment Data. We may collect data necessary to process your payment if
        you make purchases, such as your payment instrument number, and the
        security code associated with your payment instrument. All payment data
        is stored by __________. You may find their privacy notice link(s) here:
        __________.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      2. HOW DO WE PROCESS YOUR INFORMATION?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        To facilitate account creation and authentication and otherwise manage
        user accounts. We may process your information so you can create and log
        in to your account, as well as keep your account in working order.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        To save or protect an individual's vital interest.
      </span>
      <span style={{ color: "#595959" }}>
        &nbsp;We may process your information when necessary to save or protect
        an individual’s vital interest, such as to prevent harm.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We only process your personal information when we believe it
          is necessary and we have a valid legal reason (i.e., legal basis) to
          do so under applicable law, like with your consent, to comply with
          laws, to provide you with services to enter into or fulfill our
          contractual obligations, to protect your rights, or to fulfill our
          legitimate business interests.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <u>
          <span style={{ color: "#595959" }}>
            If you are located in the EU or UK, this section applies to you.
          </span>
        </u>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        Consent. We may process your information if you have given us permission
        (i.e., consent) to use your personal information for a specific purpose.
        You can withdraw your consent at any time. Learn more about&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#withdrawconsent"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            withdrawing your consent
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        Legal Obligations.
      </span>
      <span style={{ color: "#595959" }}>
        &nbsp;We may process your information where we believe it is necessary
        for compliance with our legal obligations, such as to cooperate with a
        law enforcement body or regulatory agency, exercise or defend our legal
        rights, or disclose your information as evidence in litigation in which
        we are involved.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        Vital Interests.
      </span>
      <span style={{ color: "#595959" }}>
        &nbsp;We may process your information where we believe it is necessary
        to protect your vital interests or the vital interests of a third party,
        such as situations involving potential threats to the safety of any
        person.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <u>
          <span style={{ color: "#595959" }}>
            If you are located in Canada, this section applies to you.
          </span>
        </u>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may process your information if you have given us specific permission
        (i.e., express consent) to use your personal information for a specific
        purpose, or in situations where your permission can be inferred (i.e.,
        implied consent). You can&nbsp;
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#withdrawconsent"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#003afa" }}>withdraw your consent</span>
        </u>
      </a>
      <span style={{ color: "#595959" }}>&nbsp;at any time.</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        In some exceptional cases, we may be legally permitted under applicable
        law to process your information without your consent, including, for
        example:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If collection is clearly in the interests of an individual and consent
        cannot be obtained in a timely way
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        For investigations and fraud detection and prevention
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        For business transactions provided certain conditions are met
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If it is contained in a witness statement and the collection is
        necessary to assess, process, or settle an insurance claim
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        For identifying injured, ill, or deceased persons and communicating with
        next of kin
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If we have reasonable grounds to believe an individual has been, is, or
        may be victim of financial abuse
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If it is reasonable to expect collection and use with consent would
        compromise the availability or the accuracy of the information and the
        collection is reasonable for purposes related to investigating a breach
        of an agreement or a contravention of the laws of Canada or a province
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "11.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If disclosure is required to comply with a subpoena, warrant, court
        order, or rules of the court relating to the production of records
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If it was produced by an individual in the course of their employment,
        business, or profession and the collection is consistent with the
        purposes for which the information was produced
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If the collection is solely for journalistic, artistic, or literary
        purposes
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        If the information is publicly available and is specified by the
        regulations
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We may share information in specific situations described in
          this section and/or with the following third parties.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may need to share your personal information in the following
        situations:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Business Transfers. We may share or transfer your information in
        connection with, or during negotiations of, any merger, sale of company
        assets, financing, or acquisition of all or a portion of our business to
        another
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may use cookies and similar tracking technologies (like web beacons
        and pixels) to access or store information. Specific information about
        how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      6. HOW LONG DO WE KEEP YOUR INFORMATION?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy notice unless otherwise required
          by law.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than three (3)
        months past the termination of the user's account.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      7. HOW DO WE KEEP YOUR INFORMATION SAFE?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      8. DO WE COLLECT INFORMATION FROM MINORS?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent’s use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        may have collected from children under age 18, please contact us at
        sanket@mailatmars.com.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      9. WHAT ARE YOUR PRIVACY RIGHTS?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: In some regions, such as the European Economic Area (EEA),
          United Kingdom (UK), Switzerland, and Canada, you have rights that
          allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any
          time.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        In some regions (like the EEA, UK, Switzerland, and Canada), you have
        certain rights under applicable data protection laws. These may include
        the right (i) to request access and obtain a copy of your personal
        information, (ii) to request rectification or erasure; (iii) to restrict
        the processing of your personal information; (iv) if applicable, to data
        portability; and (v) not to be subject to automated decision-making. In
        certain circumstances, you may also have the right to object to the
        processing of your personal information. You can make such a request by
        contacting us by using the contact details provided in the section "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#contact"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        " below.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your&nbsp;
      </span>
      <a
        href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#3030f1" }}>
            Member State data protection authority
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;or&nbsp;
      </span>
      <a
        href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            UK data protection authority
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        If you are located in Switzerland, you may contact the&nbsp;
      </span>
      <a
        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            Federal Data Protection and Information Commissioner
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span
          style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
        >
          Withdrawing your consent:
        </span>
      </u>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;If we are relying on your consent to process your personal
        information, which may be express and/or implied consent depending on
        the applicable law, you have the right to withdraw your consent at any
        time. You can withdraw your consent at any time by contacting us by
        using the contact details provided in the section "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#contact"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        " below or updating your preferences.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span
          style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
        >
          Opting out of marketing and promotional communications:&nbsp;
        </span>
      </u>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#contact"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        " below. You will then be removed from the marketing lists. However, we
        may still communicate with you — for example, to send you
        service-related messages that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Account Information
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Log in to your account settings and update your user account.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Contact us using the contact information provided.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Upon your request to terminate your account, we will deactivate or
        delete your account and information from our active databases. However,
        we may retain some information in our files to prevent fraud,
        troubleshoot problems, assist with any investigations, enforce our legal
        terms and/or comply with applicable legal requirements.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Cookies and similar technologies:
        </span>
      </u>
      <span style={{ color: "#595959" }}>
        &nbsp;Most Web browsers are set to accept cookies by default. If you
        prefer, you can usually choose to set your browser to remove cookies and
        to reject cookies. If you choose to remove cookies or reject cookies,
        this could affect certain features or services of our Services.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you have questions or comments about your privacy rights, you may
        email us at&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        sales@mailatmars.com
      </span>
      <span style={{ color: "#595959" }}>.</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      10. CONTROLS FOR DO-NOT-TRACK FEATURES
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: If you are a resident of California, Colorado, Connecticut,
          Utah or Virginia, you are granted specific rights regarding access to
          your personal information.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        What categories of personal information do we collect?
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <table
      cellSpacing={0}
      cellPadding={0}
      style={{ width: "468.75pt", borderCollapse: "collapse" }}
    >
      <tbody>
        <tr style={{ height: "25pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              borderLeftStyle: "solid",
              borderLeftWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "115%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>Category</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "115%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>Examples</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "115%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>Collected</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              borderLeftStyle: "solid",
              borderLeftWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>A. Identifiers</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "middle"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "115%",
        fontSize: "12pt"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <table
      cellSpacing={0}
      cellPadding={0}
      style={{ width: "468.75pt", borderCollapse: "collapse" }}
    >
      <tbody>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              borderLeftStyle: "solid",
              borderLeftWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                B. Personal information as defined in the California Customer
                Records statute
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Name, contact information, education, employment, employment
                history, and financial information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "115%",
        fontSize: "12pt"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <table
      cellSpacing={0}
      cellPadding={0}
      style={{
        width: "468.75pt",
        border: "0.75pt solid #000000",
        borderCollapse: "collapse"
      }}
    >
      <tbody>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                C. Protected classification characteristics under state or
                federal law
              </span>
            </p>
          </td>
          <td
            style={{
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>Gender and date of birth</span>
            </p>
          </td>
          <td style={{ padding: "5pt 4.62pt 5pt 5pt", verticalAlign: "top" }}>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                D. Commercial information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Transaction information, purchase history, financial details,
                and payment information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>E. Biometric information</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Fingerprints and voiceprints
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                F. Internet or other similar network activity
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, systems, and advertisements
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>G. Geolocation data</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>Device location</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Images and audio, video or call recordings created in connection
                with our business activities
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                I. Professional or employment-related information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Business contact details in order to provide you our Services at
                a business level or job title, work history, and professional
                qualifications if you apply for a job with us
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>YES</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>J. Education Information</span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Student records and directory information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "64pt" }}>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              borderBottomStyle: "solid",
              borderBottomWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                K. Inferences drawn from collected personal information
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              borderBottomStyle: "solid",
              borderBottomWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual’s preferences and characteristics
              </span>
            </p>
          </td>
          <td
            style={{
              borderTopStyle: "solid",
              borderTopWidth: "0.75pt",
              borderBottomStyle: "solid",
              borderBottomWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
        <tr style={{ height: "55pt" }}>
          <td
            style={{
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>
                L. Sensitive personal Information
              </span>
            </p>
          </td>
          <td
            style={{
              borderRightStyle: "solid",
              borderRightWidth: "0.75pt",
              padding: "5pt 4.62pt 5pt 5pt",
              verticalAlign: "top"
            }}
          >
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                lineHeight: "150%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
          <td style={{ padding: "5pt 4.62pt 5pt 5pt", verticalAlign: "top" }}>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "115%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "150%",
                fontSize: "10.5pt"
              }}
            >
              <span style={{ color: "#595959" }}>NO</span>
            </p>
            <p
              style={{
                marginTop: "0pt",
                marginBottom: "0pt",
                textAlign: "center",
                lineHeight: "115%",
                fontSize: "12pt"
              }}
            >
              <span style={{ color: "#212529" }}>&nbsp;</span>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We will use and retain the collected personal information as needed to
        provide the Services or for:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Category I - As long as the user has an account with us
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Receiving help through our customer support channels;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Participation in customer surveys or contests; and
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Facilitation in the delivery of our Services and to respond to your
        inquiries.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        How do we use and share your personal information?
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Learn about how we use your personal information in the section, "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#infouse"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#003afa" }}>
            HOW DO WE PROCESS YOUR INFORMATION?
          </span>
        </u>
      </a>
      <span style={{ color: "#595959" }}>"</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Will your information be shared with anyone else?
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Learn more about how we disclose personal information to in the section,
        "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#whoshare"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#003afa" }}>
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        "
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We have not disclosed, sold, or shared any personal information to third
        parties for a business or commercial purpose in the preceding twelve
        (12) months. We will not sell or share personal information in the
        future belonging to website visitors, users, and other consumers.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      California Residents
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law permits our users who are California residents to request and
        obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Services, you have the right to request
        removal of unwanted data that you publicly post on the Services. To
        request removal of such data, please contact us using the contact
        information provided below and include the email address associated with
        your account and a statement that you reside in California. We will make
        sure the data is not publicly displayed on the Services, but please be
        aware that the data may not be completely or comprehensively removed
        from all our systems (e.g., backups, etc.).
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>CCPA Privacy Notice</span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        This section applies only to California residents. Under the California
        Consumer Privacy Act (CCPA), you have the rights listed below.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        The California Code of Regulations defines a "residents" as:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "15pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "15pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        (2) every individual who is domiciled in the State of California who is
        outside the State of California for a temporary or transitory purpose
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        All other individuals are defined as "non-residents."
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Your rights with respect to your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Right to request deletion of the data — Request to delete
        </span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Right to be informed — Request to know
        </span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Depending on the circumstances, you have a right to know:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        whether we collect and use your personal information;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        the categories of personal information that we collect;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        the purposes for which the collected personal information is used;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        whether we sell or share personal information to third parties;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        the categories of personal information that we sold, shared, or
        disclosed for a business purpose;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        the categories of third parties to whom the personal information was
        sold, shared, or disclosed for a business purpose;
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        the business or commercial purpose for collecting, selling, or sharing
        personal information; and
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        the specific pieces of personal information we collected about you.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
          Rights
        </span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We will not discriminate against you if you exercise your privacy
        rights.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We do not process consumer's sensitive personal information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>Verification process</span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g., phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>Other privacy rights</span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        You may object to the processing of your personal information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        You may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the
        information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        You may request to opt out from future selling or sharing of your
        personal information to third parties. Upon receiving an opt-out
        request, we will act upon the request as soon as feasibly possible, but
        no later than fifteen (15) days from the date of the request submission.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        We will honor your opt-out preferences if you enact the&nbsp;
      </span>
      <a
        href="https://globalprivacycontrol.org/"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#003afa" }}
          >
            Global Privacy Control
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;(GPC) opt-out signal on your browser.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        To exercise these rights, you can contact us by submitting a&nbsp;
      </span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span style={{ color: "#003afa" }}>data subject access request</span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        , by email at sales@mailatmars.com, or by referring to the contact
        details at the bottom of this document. If you have a complaint about
        how we handle your data, we would like to hear from you.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Colorado Residents
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        This section applies only to Colorado residents. Under the Colorado
        Privacy Act (CPA), you have the rights listed below. However, these
        rights are not absolute, and in certain cases, we may decline your
        request as permitted by law.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to be informed whether or not we are processing your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to access your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to correct inaccuracies in your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to request deletion of your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to obtain a copy of the personal data you previously shared with
        us
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to opt out of the processing of your personal data if it is used
        for targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects ("profiling")
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>To submit a request to exercise</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;
      </span>
      <span style={{ color: "#595959" }}>these</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        rights described above, please email
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;
      </span>
      <span style={{ color: "#595959" }}>sales@mailatmars.com or</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;
      </span>
      <span style={{ color: "#595959" }}>submit a&nbsp;</span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            data subject access request
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If we decline to take action regarding your request and you wish to
        appeal our decision, please email us at sales@mailatmars.com. Within
        forty-five (45) days of receipt of an appeal, we will inform you in
        writing of any action taken or not taken in response to the appeal,
        including a written explanation of the reasons for the decisions.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Connecticut Residents
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        This section applies only to Connecticut residents. Under the
        Connecticut Data Privacy Act (CTDPA), you have the rights listed below.
        However, these rights are not absolute, and in certain cases, we may
        decline your request as permitted by law.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to be informed whether or not we are processing your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to access your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to correct inaccuracies in your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to request deletion of your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to obtain a copy of the personal data you previously shared with
        us
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to opt out of the processing of your personal data if it is used
        for targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects ("profiling")
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        To submit a request to exercise these rights described above, please
        email sales@mailatmars.com or submit a&nbsp;
      </span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            data subject access request
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "140%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If we decline to take action regarding your request and you wish to
        appeal our decision, please email us at sales@mailatmars.com. Within
        sixty (60) days of receipt of an appeal, we will inform you in writing
        of any action taken or not taken in response to the appeal, including a
        written explanation of the reasons for the decisions.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "140%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "140%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Utah Residents
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "140%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "140%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        This section applies only to Utah residents. Under the Utah Consumer
        Privacy Act (UCPA), you have the rights listed below. However, these
        rights are not absolute, and in certain cases, we may decline your
        request as permitted by law.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "140%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to be informed whether or not we are processing your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "140%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to access your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "140%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to request deletion of your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "140%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to obtain a copy of the personal data you previously shared with
        us
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "140%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to opt out of the processing of your personal data if it is used
        for targeted advertising or the sale of personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "140%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        To submit a request to exercise these rights described above, please
        email&nbsp;
      </span>
      <span
        style={{ lineHeight: "140%", fontSize: "10.5pt", color: "#595959" }}
      >
        sales@mailatmars.com
      </span>
      <span style={{ color: "#595959" }}>&nbsp;or submit a&nbsp;</span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "140%", fontSize: "10.5pt", color: "#003afa" }}
          >
            data subject access request
          </span>
        </u>
      </a>
      <span style={{ color: "#595959" }}>.</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Virginia Residents
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Under the Virginia Consumer Data Protection Act (VCDPA):
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        "Consumer" means a natural person who is a resident of the Commonwealth
        acting only in an individual or household context. It does not include a
        natural person acting in a commercial or employment context.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        "Personal data" means any information that is linked or reasonably
        linkable to an identified or identifiable natural person. "Personal
        data" does not include de-identified data or publicly available
        information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        "Sale of personal data" means the exchange of personal data for monetary
        consideration.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If this definition of "consumer" applies to you, we must adhere to
        certain rights and obligations regarding your personal data.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Your rights with respect to your personal data
        </span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to be informed whether or not we are processing your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to access your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to correct inaccuracies in your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to request deletion of your personal data
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to obtain a copy of the personal data you previously shared with
        us
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        Right to opt out of the processing of your personal data if it is used
        for targeted advertising, the sale of personal data, or profiling in
        furtherance of decisions that produce legal or similarly significant
        effects ("profiling")
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>
          Exercise your rights provided under the Virginia VCDPA
        </span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        You may contact us by email at sales@mailatmars.com or submit a&nbsp;
      </span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            data subject access request
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you are using an authorized agent to exercise your rights, we may
        deny a request if the authorized agent does not submit proof that they
        have been validly authorized to act on your behalf.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>Verification process</span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer's request. If you submit the
        request through an authorized agent, we may need to collect additional
        information to verify your identity before processing your request.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Upon receiving your request, we will respond without undue delay, but in
        all cases, within forty-five (45) days of receipt. The response period
        may be extended once by forty-five (45) additional days when reasonably
        necessary. We will inform you of any such extension within the initial
        45-day response period, together with the reason for the extension.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <u>
        <span style={{ color: "#595959" }}>Right to appeal</span>
      </u>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at sales@mailatmars.com. Within sixty (60)
        days of receipt of an appeal, we will inform you in writing of any
        action taken or not taken in response to the appeal, including a written
        explanation of the reasons for the decisions. If your appeal is denied,
        you may contact the&nbsp;
      </span>
      <a
        href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            Attorney General to submit a complaint
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: You may have additional rights based on the country you
          reside in.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Australia and New Zealand
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We collect and process your personal information under the obligations
        and conditions set by Australia's Privacy Act 1988 and New Zealand's
        Privacy Act 2020 (Privacy Act).
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        This privacy notice satisfies the notice requirements defined in both
        Privacy Acts, in particular: what personal information we collect from
        you, from which sources, for which purposes, and other recipients of
        your personal information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you do not wish to provide the personal information necessary to
        fulfill their applicable purpose, it may affect our ability to provide
        our services, in particular:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        offer you the products or services that you want
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        respond to or help with your requests
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "0pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>manage your account with us</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginLeft: "36pt",
        marginBottom: "12pt",
        textIndent: "-18pt",
        lineHeight: "150%",
        fontSize: "10.5pt"
      }}
    >
      <span style={{ fontSize: "12pt", color: "#212529" }}>■</span>
      <span
        style={{
          width: "10.75pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span style={{ color: "#595959" }}>
        confirm your identity and protect your account
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        At any time, you have the right to request access to or correction of
        your personal information. You can make such a request by contacting us
        by using the contact details provided in the section "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#request"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        "
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        If you believe we are unlawfully processing your personal information,
        you have the right to submit a complaint about a breach of the
        Australian Privacy Principles to the&nbsp;
      </span>
      <a
        href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            Office of the Australian Information Commissioner
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        &nbsp;and a breach of New Zealand's Privacy Principles to the&nbsp;
      </span>
      <a
        href="https://www.privacy.org.nz/your-rights/making-a-complaint/"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            Office of New Zealand Privacy Commissioner
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "13pt",
        backgroundColor: "#ffffff"
      }}
    >
      Republic of South Africa
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        At any time, you have the right to request access to or correction of
        your personal information. You can make such a request by contacting us
        by using the contact details provided in the section "
      </span>
      <a
        href="https://mailatmars.com/company/privacy/#request"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        "
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you are unsatisfied with the manner in which we address any complaint
        with regard to our processing of personal information, you can contact
        the office of the regulator, the details of which are:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <a
        href="https://inforegulator.org.za/"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            The Information Regulator (South Africa)
          </span>
        </u>
      </a>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>General enquiries:&nbsp;</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
      >
        enquiries@inforegulator.org.za
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        Complaints (complete POPIA/PAIA form 5):&nbsp;
      </span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
      >
        PAIAComplaints@inforegulator.org.za
      </span>
      <span style={{ color: "#595959" }}>&nbsp;&amp;&nbsp;</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
      >
        POPIAComplaints@inforegulator.org.za
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      13. DO WE MAKE UPDATES TO THIS NOTICE?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <em>
        <span style={{ color: "#595959" }}>
          In Short: Yes, we will update this notice as necessary to stay
          compliant with relevant laws.
        </span>
      </em>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>
        If you have questions or comments about this notice, you may email us at
        sanket@mailatmars.com or contact us by post at:
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>mailatmars technologies LLP</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>1262 SAINAGAR,</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>MEDANKARWADI, CHAKAN,</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "11.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        PUNE
      </span>
      <span style={{ color: "#595959" }}>,&nbsp;</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        MAHARASTRA
      </span>
      <span style={{ color: "#595959" }}>&nbsp;</span>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        410501
      </span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "10.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#595959" }}>India</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "14.5pt",
        backgroundColor: "#ffffff"
      }}
    >
      15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        fontSize: "12pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ color: "#212529" }}>&nbsp;</span>
    </p>
    <p
      style={{
        marginTop: "0pt",
        marginBottom: "0pt",
        lineHeight: "150%",
        backgroundColor: "#ffffff"
      }}
    >
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        You have the right to request access to the personal information we
        collect from you, change that information, or delete it. To request to
        review, update, or delete your personal information, please fill out and
        submit a&nbsp;
      </span>
      <a
        href="https://app.termly.io/notify/0e41bad8-a792-43cb-9f1c-043475b9c46e"
        style={{ textDecoration: "none" }}
      >
        <u>
          <span
            style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#3030f1" }}
          >
            data subject access request
          </span>
        </u>
      </a>
      <span
        style={{ lineHeight: "150%", fontSize: "10.5pt", color: "#595959" }}
      >
        .
      </span>
    </p>
    <p style={{ marginTop: "0pt", marginBottom: "0pt" }}>&nbsp;</p>
  </div>
  <p style={{ bottom: 10, right: 10, position: "absolute" }}>
    <a
      href="https://wordtohtml.net"
      target="_blank"
      style={{ fontSize: 11, color: "#d0d0d0" }}
    >
      Converted to HTML with WordToHTML.net
    </a>
  </p>
</>

    </div>



<Footer />

    </div>
  )
}
