import { Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverGroup, PopoverPanel, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, ArrowPathIcon, PaperAirplaneIcon, CheckBadgeIcon, CodeBracketSquareIcon, RectangleStackIcon, ChartPieIcon, ArrowDownOnSquareStackIcon, CursorArrowRaysIcon, FingerPrintIcon, SquaresPlusIcon, CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
import { Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';
import Alternative from '../component/alternatives.js';
import AISuggestion from './openai.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const logo = "https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Upload list',
    description: 'Effortlessly import your entire list, including additional columns, with just one click. Streamline your data management process and get started with ease.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Own domain',
    description: 'Enhance your email reputation by using your own domain. Build trust with recipients and improve deliverability by aligning your emails with your brand identity.',
    icon: LockClosedIcon,
  },
  {
    name: 'Email editor',
    description: 'Enjoy the convenience of our responsive email editor built directly into our platform, ensuring your emails look great on any device.',
    icon: CodeBracketSquareIcon,
  },
];

const features_new = [
  { name: 'Batch campaign', description: 'Dispatches batches at hourly intervals has notably enhanced deliverability', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
];

const products = [
  { name: 'Batch campaign', description: 'Dispatches batches at hourly intervals has notably enhanced deliverability', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
];
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
];

export default function SubjectLineSuggestion() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [subjectLine, setSubjectLine] = useState('');
  const [suggestion, setSuggestion] = useState(null);

  const handleInputChange = (e) => {
    setSubjectLine(e.target.value);
  };

  const handleSuggestionChange = (newSuggestion) => {
    setSuggestion(newSuggestion);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success('Copied to clipboard');
  };

  return (
    <div className="bg-white">
      <Header />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-24 sm:py-24 lg:py-24">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
             Email subject line suggetion
            </h1>
            <div className="flex mt-6">
              <input
                type="text"
                value={subjectLine}
                onChange={handleInputChange}
                placeholder="Enter your subject line or Topic here..."
                className="w-full border border-gray-300 rounded-l-lg py-3 px-4 text-lg focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
              />
              <AISuggestion subjectLine={subjectLine} onReceiveSuggestion={handleSuggestionChange} />
            </div>
            {suggestion && (
              <div className="mt-4">
                <table className="min-w-full bg-white mt-2">
                  <thead>
                    <tr>
                      <th className="py-4 px-6 border-b text-left w-1/10"></th>
                      <th className="py-4 px-6 border-b text-left w-1/2"></th>
                      <th className="py-4 px-6 border-b text-left w-1/5"></th>
                      <th className="py-4 px-6 border-b text-left w-1/5"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {suggestion.suggestions.map((item, index) => (
                      <tr key={index}>
                        <td className="py-4 px-6 border-b text-left">
                          <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(item.line)} />
                        </td>
                        <td className="py-4 px-6 border-b text-left">{item.line}</td>
                        <td className="py-4 px-6 border-b ">{item.open_rate}%</td>
                        <td className="py-4 px-6 border-b">
                          <Button type="primary" style={{background:"#4f46e5"}} href="https://account-v2.mailatmars.com/account/create">
                            Try for free
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>

      <Alternative />

      <Pricing />

      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">Ease of email marketing</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Easy and simple for beginners</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Elevate your email marketing game: Utilize your own domain, leverage our intuitive in-built email editor, and streamline your campaigns with our efficient list manager.
                </p>
                <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
            <img
              src="https://res.cloudinary.com/dttmahifk/image/upload/v1717238002/Screenshot_2024-06-01_at_4.02.56_PM_auimfs.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
      </div>

      <Feature />

      <Stats />

      <Review />

      <Cta />

      <Footer />
     
    </div>
  );
}
