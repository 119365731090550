import { Fragment,useState } from 'react'
import {Helmet} from "react-helmet";
import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon,
ChevronDoubleRightIcon,
DocumentTextIcon,
BoltIcon

 } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Header from '../component/header.js';
import Pricing from '../component/pricing.js';
import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Advanced analytics',
    description:
      'Receive separate analytics for each drip campaign, enabling you to track performance, optimize strategies, and boost engagement effectively',
    icon: ChartPieIcon,
  }
]


const feature_of_template=[
  {
    name: 'Own coded design',
    description:
      'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
]

const features_new=[
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]

const products = [
  { name: 'Batch campaign', description: 'dispatches batches at hourly intervals has notably enhanced deliveribility', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
]


export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className="bg-white">

  <Header />


      <div className="relative isolate  pt-14 ">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        <div class="overflow-hidden pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
            <div className="hidden sm:mb-8 sm:flex sm:justify-left">
              <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                Automated series for targeted outreach

              </div>
            </div>
              <div className="lg:max-w-lg">

                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> Automated sequence for personalized and timely engagement</p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
              Mailatmars drip campaign feature allows you to automate personalized email sequences, delivering timely and targeted messages to nurture leads and drive conversions effectively
                </p>

              </div>
              <div className="mt-10 flex items-center  gap-x-6">
                <a
                  href="https://account-v2.mailatmars.com/account/create"
                  className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get started
                </a>
                <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
              </div>
            </div>
            <img
              src="https://res.cloudinary.com/dttmahifk/image/upload/v1717410992/Untitled_design_5_vhcfyb.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
            />
          </div>
        </div>
        </div>


        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#e2c44b] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>







<div className="overflow-hidden bg-white py-24 sm:py-32">
     <div className="mx-auto max-w-7xl px-6 lg:px-8">
       <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
         <div className="lg:pr-8 lg:pt-4">
           <div className="lg:max-w-lg">
             <h2 className="text-base font-semibold leading-7 text-indigo-600">How Drips works</h2>
             <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Automate Personalized Sequences for Lead Nurturing</p>
             <p className="mt-6 text-lg leading-8 text-gray-600">
              Mailatmars' email drip campaigns automate personalized sequences, delivering targeted messages over time. This strategy nurtures leads, boosts engagement, and drives conversions, ensuring your audience receives relevant content at the right moments
             </p>
             <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
               {features.map((feature) => (
                 <div key={feature.name} className="relative pl-9">
                   <dt className="inline font-semibold text-gray-900">
                     <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                     {feature.name}
                   </dt>{' '}
                   <dd className="inline">{feature.description}</dd>
                 </div>
               ))}
             </dl>
           </div>
         </div>
         <img
           src="https://res.cloudinary.com/dttmahifk/image/upload/v1717387827/Screenshot_2024-06-03_at_9.40.02_AM_g8jihe.png"
           alt="Product screenshot"
           className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
           width={2432}
           height={1442}
         />
       </div>
     </div>
   </div>


   <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">

         <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
           <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
             <div className="lg:pr-4">
               <div className="lg:max-w-lg">
                 <p className="text-base font-semibold leading-7 text-indigo-600">Design Email</p>
                 <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Craft Stunning Emails with Ease</h1>
                 <p className="mt-6 text-xl leading-8 text-gray-700">
                   Mailatmars' email editor lets you effortlessly craft beautiful, responsive emails. Enjoy drag-and-drop design, customizable templates, and real-time previews to ensure your campaigns look perfect on any device
                 </p>
               </div>
             </div>
           </div>
           <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
             <img
               className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
               src="https://res.cloudinary.com/dttmahifk/image/upload/v1717323828/Screenshot_2024-06-02_at_3.53.25_PM_eedbno.png"
               alt=""
             />
           </div>
           <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
             <div className="lg:pr-4">
               <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">

               <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                 {feature_of_template.map((feature) => (
                   <div key={feature.name} className="relative pl-9">
                     <dt className="inline font-semibold text-gray-900">
                       <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                       {feature.name}
                     </dt>{' '}
                     <dd className="inline">{feature.description}</dd>
                   </div>
                 ))}
               </dl>



                 <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Responsive Email</h2>
                 <p className="mt-6">
                   Craft responsive emails with Mailatmars, ensuring your messages adapt seamlessly to any device, delivering an optimal viewing experience for all recipients
                 </p>
               </div>
             </div>
           </div>
         </div>
       </div>

<Feature />


  <Pricing />

<Stats />

<Review />

    <Cta />

<Footer />
 
    </div>
  )
}
