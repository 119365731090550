import { Fragment, useState, useEffect } from 'react';
import { Col, Row, Slider, Select, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons'; // Import Ant Design Icon

const free_feature = ["Advanced AI", "Design & Code Editors", "Create Campaign", "Campaign Analytics", "Segmentation", "Ticket Support", "Mailatmars Branding"];
const lite_feature = ["Advanced AI", "All Free features", "Unlimited Contacts", "Personalization", "Dynamic content in email", "Report Exporting", "3 Steps Drip campaign", "Live Support", "No Mailatmars Branding"];
const premium_feature = ["Advanced AI", "Everything In Lite", "Batch Campaign", "A/B Testing Campaign", "20 steps in drip campaign", "24*7 WhatsApp support"];

const marks = {
  10000: '10k',
  100000: '100k',
  200000: '200k',
  300000: '300k',
  400000: '400k',
  500000: '500k',
  600000: '600k',
};

export default function Example() {
  const [inputValue, setInputValue] = useState(10000);
  const [currency, setCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(1); // Default USD rate
  const [litePlanBase, setLitePlanBase] = useState(8); // Base price in USD
  const [premiumPlanBase, setPremiumPlanBase] = useState(9); // Base price in USD
  const [symbol, setSymbol] = useState("$");

  const currencyOptions = [
    { value: "USD", label: "USD ($)" },
    { value: "EUR", label: "EUR (€)" },
    { value: "GBP", label: "GBP (£)" },
    { value: "INR", label: "INR (₹)" },
  ];

  useEffect(() => {
    async function fetchExchangeRate(userCurrency = "USD") {
      try {
        const ratesResponse = await fetch(`https://api.exchangerate-api.com/v4/latest/USD`);
        const ratesData = await ratesResponse.json();
        const rate = ratesData.rates[userCurrency] || 1;
        setExchangeRate(rate);

        const currencySymbols = { USD: "$", EUR: "€", GBP: "£", INR: "₹" };
        setSymbol(currencySymbols[userCurrency] || "$");
      } catch (error) {
        console.error("Error fetching currency:", error);
      }
    }

    fetchExchangeRate(currency);
  }, [currency]);

  const calculatePrice = (basePrice) => Math.round(basePrice * exchangeRate);

  const onCurrencyChange = (value) => {
    setCurrency(value);
  };

  const onChange = (val) => {
    let premiumPrice = 0;
    let litePrice = 0;
    let emailCount = 0;

    if (val < 10000) {
      premiumPrice = 9;
      litePrice = 8;
      emailCount = 10000;
    } else if (val < 20000) {
      premiumPrice = 16;
      litePrice = 14;
      emailCount = 20000;
    } else if (val < 100000) {
      premiumPrice = 69;
      litePrice = 60;
      emailCount = 100000;
    } else if (val < 200000) {
      premiumPrice = 130;
      emailCount = 200000;
    } else if (val < 300000) {
      premiumPrice = 190;
      emailCount = 300000;
    } else if (val < 600000) {
      premiumPrice = 310;
      emailCount = 500000;
    }

    setLitePlanBase(litePrice);
    setPremiumPlanBase(premiumPrice);
    setInputValue(emailCount);
  };

  return (
    <div>
      <div className="p-10">
        <h1 className="text-4xl font-bold text-gray-900 text-center">Pricing</h1>

        {/* Aligned Slider and Currency Selector */}
        <Row style={{ justifyContent: "center", padding: "40px" }} align="middle">
          <Col xl={14} xs={24}>
            <Slider marks={marks} min={10000} max={600000} onChange={onChange} value={inputValue} />
          </Col>

          <Col xl={6} xs={24} style={{ textAlign: "center" }}>
            <Select
              style={{ width: 150 }}
              value={currency}
              onChange={onCurrencyChange}
              options={currencyOptions}
            />
          </Col>
        </Row>

        <div className="grid sm:grid-cols-3 gap-6 max-w-5xl mx-auto">
          {/* Free Plan */}
          <PricingCard
            title="Free"
            price={0}
            features={free_feature}
            inputValue={inputValue}
            actionButton={
              <Button
                type="primary"
                
                className="rounded-md block bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Free Plan
              </Button>
            }
          />
          {/* Lite Plan */}
          <PricingCard
            title="Lite"
            price={`${symbol}${calculatePrice(litePlanBase)}/mo`}
            features={lite_feature}
            inputValue={inputValue}
            actionButton={
              <a href="https://account-v2.mailatmars.com/account/create">
                <Button
                  type="primary"
                  disabled={inputValue > 100000}
                  className="rounded-md block bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {inputValue > 100000 ? "Unavailable for this range" : "Go for Lite Plan"}
                </Button>
              </a>
            }
          />
          {/* Premium Plan */}
          <PricingCard
            title="Premium"
            price={`${symbol}${calculatePrice(premiumPlanBase)}/mo`}
            features={premium_feature}
            inputValue={inputValue}
            actionButton={
              <a href="https://account-v2.mailatmars.com/account/create">
                <Button
                  type="primary"
                  className=" bg-indigo-600 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Go for Premium Plan
                </Button>
              </a>
            }
          />
        </div>
      </div>
    </div>
  );
}

function PricingCard({ title, price, features, inputValue, actionButton }) {
  return (
    <div className="border rounded-lg shadow-sm p-6">
      <h2 className="text-xl font-bold">{title}</h2>
      <p className="mt-8 text-4xl font-bold">{price}</p>
      <p className="text-sm text-gray-500">{inputValue && `For ${inputValue} emails`}</p>
      <div className="mt-2">{actionButton}</div>
      <ul className="mt-4 space-y-3">
        {features.map((feature) => (
          <li key={feature} className="flex space-x-3">
            <CheckCircleOutlined style={{ color: "green" }} /> {/* Ant Design green icon */}
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
