import { Fragment, useState } from 'react';
import { Helmet } from "react-helmet";
import {  Field, Label, Switch } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, ArrowPathIcon, PaperAirplaneIcon, CheckBadgeIcon, CodeBracketSquareIcon, RectangleStackIcon, ChartPieIcon, ArrowDownOnSquareStackIcon, CursorArrowRaysIcon, FingerPrintIcon, SquaresPlusIcon, CloudArrowUpIcon, LockClosedIcon, ServerIcon, ChevronDoubleRightIcon, DocumentTextIcon, BoltIcon, RectangleGroupIcon, UsersIcon, SparklesIcon, RocketLaunchIcon, MapPinIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import Header from '../component/header.js';

import Feature from '../component/feature.js';
import Stats from '../component/stat.js';
import Review from '../component/review.js';
import Cta from '../component/cta.js';
import Footer from '../component/footer.js';
import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { message } from 'antd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const logo = "https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";

const features = [
  {
    name: 'Responsive email block',
    description: 'Incorporate responsive email blocks in Mailatmars editor to ensure your content looks great on all devices effortlessly',
    icon: RectangleGroupIcon,
  },
  {
    name: 'Unique 5+ blocks',
    description: 'Header, product showcase, testimonial, social media integration, and footer blocks enhance brand identity and engagement in branded email templates',
    icon: SquaresPlusIcon,
  },
  {
    name: 'Easy integrate social media',
    description: 'Effortlessly incorporate social media icons into your emails using Mailatmars intuitive email editor for enhanced engagement and connectivity.',
    icon: UsersIcon,
  },
  {
    name: 'Test in various plateform',
    description: 'Straightforward test email editor streamlines the process, facilitating quick iteration and optimization for superior campaign results',
    icon: SparklesIcon,
  },
  {
    name: 'No branding in free plan',
    description: 'Access Mailatmars email editor for free, empowering you to design and send professional campaigns without any financial commitment',
    icon: RocketLaunchIcon,
  },
];

const feature_of_template = [
  {
    name: 'Own coded design',
    description: 'Easily upload your email template code to Mailatmars for seamless integration and personalized campaign design tailored to your brand',
    icon: CodeBracketSquareIcon,
  },
  {
    name: 'In-built text editor',
    description: 'Streamline email creation with Mailatmars simple text editor. Write, edit, and send emails effortlessly for efficient communication with your audience',
    icon: DocumentTextIcon,
  },
  {
    name: 'Pre-built Template',
    description: 'Utilize Mailatmars pre-built email templates for quick and professional campaign creation tailored to your unique messaging needs',
    icon: BoltIcon,
  },
];

const features_new = [
  { name: 'Batch campaign', description: 'Dispatches batches at hourly intervals has notably enhanced deliverability', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
];

const products = [
  { name: 'Batch campaign', description: 'Dispatches batches at hourly intervals has notably enhanced deliverability', href: '#', icon: RectangleStackIcon },
  { name: 'A/B testing', description: 'Experimenting with variations to optimize performance and engagement', href: '#', icon: CheckBadgeIcon },
  { name: 'Email editor', description: 'Customize, design, and craft compelling messages effortlessly for campaigns', href: '#', icon: CodeBracketSquareIcon },
  { name: 'Drip campaign', description: 'Setup series of email campaign and generate qualify leads', href: '#', icon: ArrowDownOnSquareStackIcon },
  { name: 'Analytics', description: 'Insights track performance, engagement, and optimize marketing strategies', href: '#', icon: ChartPieIcon },
];
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PhoneIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
];

const links = [
  { name: 'Secure', href: '#' },
  { name: 'Safe', href: '#' },
  { name: 'values', href: '#' },
  { name: 'leadership', href: '#' },
];
const stats = [
  { name: 'Offices worldwide', value: '3' },
  { name: 'Full-time colleagues', value: '300+' },
  { name: 'Enterprise use', value: '40+' },
  { name: 'Emails per month', value: '5M+' },
];

const people = [
  {
    name: 'Ravi Gorasiya',
    role: 'Founder / CEO',
    imageUrl: 'https://res.cloudinary.com/dttmahifk/image/upload/v1717477196/ravi-photo_ftlnaa.jpg',
    linkedin_url: "https://linkedin.com/in/ravi-gorasiya"
  },
  {
    name: 'Sanket Pawar',
    role: 'Co-Founder / CMO',
    imageUrl: 'https://res.cloudinary.com/dttmahifk/image/upload/v1717477629/sanket-photo_gkcni2.jpg',
    linkedin_url: "https://www.linkedin.com/in/sanketpawar7/"
  },
];

export default function Example() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phoneNumber: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

   
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData)
    };

    try {
      const response = await fetch('https://account.mailatmars.com/account-v2/contact/contact.php', requestOptions);
      const result = await response.json();
      if (response.ok) {
        message.success('Form submitted successfully! Team will connect with you shortly.');
      } else {
        message.error(result.message || 'Failed to submit the form. Please try again.');
      }
    } catch (error) {
      message.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-white">
      <Header />

      <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        >
          <div
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
            className="relative left-1/2 -z-10 aspect-1155/678 w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-linear-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          />
        </div>
        <div className="mx-auto max-w-7xl">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div>
              <h2 className="text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl">Contact sales</h2>
              <p className="mt-2 text-lg/8 text-gray-600">Reach out to our sales team for assistance and inquiries.</p>
              <form onSubmit={handleSubmit} className="mt-16 max-w-xl">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                  <div>
                    <label htmlFor="first-name" className="block text-sm/6 font-semibold text-gray-900">
                      First name
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="first-name"
                        name="firstName"
                        type="text"
                        autoComplete="given-name"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 px-3.5 py-2 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="last-name" className="block text-sm/6 font-semibold text-gray-900">
                      Last name
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="last-name"
                        name="lastName"
                        type="text"
                        autoComplete="family-name"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 px-3.5 py-2 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="company" className="block text-sm/6 font-semibold text-gray-900">
                      Company
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="company"
                        name="company"
                        type="text"
                        autoComplete="organization"
                        value={formData.company}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 px-3.5 py-2 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900">
                      Email
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 px-3.5 py-2 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="phone-number" className="block text-sm/6 font-semibold text-gray-900">
                      Phone number
                    </label>
                    <div className="mt-2.5">
                      <input
                        id="phone-number"
                        name="phoneNumber"
                        type="text"
                        placeholder="+91 123-456-7890"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 px-3.5 py-2 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="message" className="block text-sm/6 font-semibold text-gray-900">
                      Message
                    </label>
                    <div className="mt-2.5">
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        className="block w-full rounded-md border border-gray-300 px-3.5 py-2 text-base text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-indigo-600"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <button
                    type="submit"
                    className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {loading ? 'Submitting...' : "Let's talk"}
                  </button>
                </div>
              </form>
            </div>
            <div>
              <h2 className="text-4xl font-semibold tracking-tight text-balance text-gray-900 sm:text-5xl">Get in touch</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Have questions or need support? Get in touch with us – we're here to help you elevate your email marketing with MailatMars!
              </p>
              <div className='container-main-contact mt-6'>
                <div className="flex items-center mb-4">
                  <MapPinIcon className="h-6 w-6 text-indigo-600 mr-2" />
                  <span className="text-lg text-gray-600">Pune, India</span>
                </div>
                <div className="flex items-center mb-4">
                  <PhoneIcon className="h-6 w-6 text-indigo-600 mr-2" />
                  <a href="https://wa.me/917016992938" className="text-lg text-gray-600">+91 70169 92938</a>
                </div>
                <div className="flex items-center">
                  <EnvelopeIcon className="h-6 w-6 text-indigo-600 mr-2" />
                  <span className="text-lg text-gray-600">sanket@mailatmars.com</span>
                
                </div>
                <div className="flex items-center" style={{marginTop:20}}>


                <a 
  href="https://calendly.com/ravi-mailatmars/30min" 
  target="_blank" 
  rel="noopener noreferrer" 
  className="text-sm font-semibold leading-6 text-gray-900 border border-gray-900 px-4 py-2 rounded-md hover:bg-gray-900 hover:text-white transition"
>
  Shedule Free Demo <span aria-hidden="true">→</span>
</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      <Footer />
    </div>
  );
}
